import { lazy } from "react";
import { Loadable } from "../components/organisms/Loadable";
const Login = Loadable(lazy(() => import("../pages/Autenticacao/Login")));
const Empresa = Loadable(lazy(() => import("../pages/Autenticacao/Empresa")));
const RecuperarSenha = Loadable(
  lazy(() => import("../pages/Autenticacao/RecuperarSenha"))
);
const Registrar = Loadable(
  lazy(() => import("../pages/Autenticacao/Registrar"))
);
const Recusar = Loadable(lazy(() => import("../pages/Autenticacao/Recusar")));
const Aceitar = Loadable(lazy(() => import("../pages/Autenticacao/Aceitar")));
const Novo = Loadable(lazy(() => import("../pages/Autenticacao/Novo")));
const Logout = Loadable(lazy(() => import("../pages/Autenticacao/Logout")));
const Renovar = Loadable(lazy(() => import("../pages/Autenticacao/Renovar")));
const ResetarSenha = Loadable(lazy(() => import("../pages/Autenticacao/ResetarSenha")));
const Pagina404 = Loadable(lazy(() => import("../pages/Erro/Pagina404")));

const naoAutenticadoRoutes = [
  { path: "/Login", element: <Login /> },
  { path: "/Empresa", element: <Empresa /> },
  { path: "/Registrar/:sistemaClienteId/:id", element: <Registrar /> },
  { path: "/Recusar/:sistemaClienteId/:id", element: <Recusar /> },
  { path: "/Aceitar/:sistemaClienteId/:id", element: <Aceitar /> },
  { path: "/ResetarSenha/:id", element: <ResetarSenha /> },
  { path: "/RecuperarSenha", element: <RecuperarSenha /> },
  { path: "/Novo", element: <Novo /> },
  { path: "/Logout", element: <Logout /> },
  { path: "/Renovar", element: <Renovar /> },
  { path: "/404", element: <Pagina404 /> },
];

export default naoAutenticadoRoutes;
