import React, { forwardRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  ChildrenForm,
  FileForm,
  AdditionalForm,
  FormRef,
} from "src/SmartR";

import gomelius from "src/api/gomelius";

import { Card, Loading, PaginaLayout } from "src/components";
export interface Dependencia {
  urlGetList: string;
  dispatch: React.Dispatch<React.SetStateAction<any[]>>;
  filter?: any;
  handleList?: any;
  onLoaded?: any;
}

interface PaginaCadastroProps {
  id?: string;
  funcao?: number;
  item: string;
  tituloMensagem?: string;
  saveMessage?: string;
  titulo: string;
  modulo: string;
  urlSubmit?: string;
  urlGet?: string;
  urlBack?: string;
  children?: any;
  obterUnico?: boolean;
  podeFavorito?: boolean;
  childrenTabs?: boolean;
  perguntarNovoRegistro?: boolean;
  mostrarMensagemSucesso?: boolean;
  dispatchForm?: any;
  classeBotaoConfirmar?: string;
  textoBotaoConfirmar?: string;
  dependencias?: Dependencia[];
  subCadastros?: ChildrenForm[];
  cadastrosAdicionais?: AdditionalForm[];
  arquivos?: FileForm[];
  onRegistroCarregado?: any;
  onRegistroSalvo?: any;
  tratarSalvoManual?: any;
  onSalvar?: any;
  onReady?: any;
  modal?: boolean;
  tipoConteudo?: string;
  beforeSubmitHandler?: (form: any) => Promise<any>;
  customValidation?: (record: any) => Promise<boolean>;
}

export const PaginaCadastro = forwardRef<FormRef, PaginaCadastroProps>(
  (
    {
      id,
      funcao,
      titulo,
      tituloMensagem = "Registro",
      modulo,
      item,
      urlGet,
      urlSubmit,
      urlBack,
      dispatchForm,
      childrenTabs = false,
      podeFavorito = true,
      perguntarNovoRegistro = true,
      mostrarMensagemSucesso = true,
      obterUnico = false,
      saveMessage,
      classeBotaoConfirmar = "btn-primary",
      textoBotaoConfirmar = "Confirmar",
      dependencias,
      subCadastros,
      cadastrosAdicionais,
      arquivos,
      onRegistroCarregado,
      onRegistroSalvo,
      tratarSalvoManual = true,
      modal = false,
      onReady,
      onSalvar,
      children,
      tipoConteudo = "application/json",
      beforeSubmitHandler,
      customValidation,
    }: PaginaCadastroProps,
    ref
  ) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const handleCancelar = () => {
      navigate(`/${urlBack}`);
    };

    // Função para promessas de registro (principal)
    const getRegistroPromises = () => {
      const promises = [];
      if (id) {
        promises.push(gomelius.obterRegistro(id, urlGet));
      } else if (obterUnico) {
        promises.push(gomelius.obter(urlGet));
      }
      return promises;
    };

    // Função para promessas de dependências
    const getDependenciaPromises = () => {
      const promises = [];
      dependencias?.forEach((dependencia) => {
        if (!dependencia.filter) {
          promises.push(gomelius.obterLista(dependencia.urlGetList));
        } else {
          promises.push(
            gomelius.obterListaFiltro(
              dependencia.filter,
              dependencia.urlGetList
            )
          );
        }
      });
      return promises;
    };

    // Função para promessas de subcadastros
    const getSubCadastroPromises = () => {
      const promises = [];
      if (id || obterUnico) {
        subCadastros?.forEach((subCadastro) => {
          if (id) {
            promises.push(
              gomelius.obterListaSubCadastro(id, subCadastro.urlGetList)
            );
          } else {
            promises.push(gomelius.obterLista(subCadastro.urlGetList));
          }
        });
      }
      return promises;
    };

    // Função para promessas de cadastros extras
    const getCadastroExtraPromises = (mainRecord: any) => {
      const promises = [];
      if (id || obterUnico) {
        cadastrosAdicionais?.forEach((cadastroExtra: AdditionalForm) => {
          const shouldSkipGet =
            typeof cadastroExtra.skipGet === "function"
              ? cadastroExtra.skipGet(mainRecord)
              : cadastroExtra.skipGet ?? false;
          if (!shouldSkipGet) {
            promises.push({
              cadastro: cadastroExtra,
              promisse: gomelius.obterRegistro(id, cadastroExtra.urlGet),
            });
          }
        });
      }
      return promises;
    };

    // Processamento de dependências
    const processDependencies = (results) => {
      dependencias?.forEach((dependencia, index) => {
        let lista = results[index];
        if (dependencia.filter) {
          lista = lista.lista;
        }
        if (dependencia.handleList) {
          lista = dependencia.handleList(lista);
        }
        if (dependencia.onLoaded) {
          dependencia.onLoaded(lista);
        }
        dependencia.dispatch(lista);
      });
    };

    // Processamento de subcadastros
    const processSubCadastros = async (results) => {
      // Garante que o resultado será um array, mesmo que subCadastros seja null ou undefined
      const subCadastrosPromises =
        subCadastros && results && results.length > 0
          ? subCadastros.map(async (subCadastro, index) => {
              let lista = results[index];

              const childrenPromises =
                subCadastro.loadRecordComplete && subCadastro.urlGet
                  ? lista.map((lazy) =>
                      gomelius.obterChildren(id, lazy.id, subCadastro.urlGet)
                    )
                  : [];

              if (childrenPromises.length > 0) {
                const resultsChildren = await Promise.all(childrenPromises);
                lista = resultsChildren.map((record) => record);
              }

              if (subCadastro.handleListOnLoad) {
                lista = subCadastro.handleListOnLoad(lista);
              }

              if (subCadastro.onLoaded) {
                subCadastro.onLoaded(lista);
              }

              subCadastro.dispatch(lista);

              return Promise.resolve(); // Retorna uma promise resolvida para compatibilidade
            })
          : []; // Retorna um array vazio se subCadastros for null ou undefined

      // Chama Promise.all somente se houver promessas no array
      if (subCadastrosPromises.length > 0) {
        await Promise.all(subCadastrosPromises);
      }
    };

    // Processamento de cadastros extras
    const processExtraCadastros = async (cadastros, results) => {
      // Garante que o resultado será um array, mesmo que cadastrosAdicionais seja null ou undefined
      const cadastrosAdicionaisPromises =
        cadastros && results && results.length > 0
          ? cadastros.map((cadastroExtra, index) => {
              let recordExtra = results[index];

              if (cadastroExtra.handleOnLoad) {
                recordExtra = cadastroExtra.handleOnLoad(recordExtra);
              }

              if (cadastroExtra.onLoaded) {
                cadastroExtra.onLoaded(recordExtra);
              }

              cadastroExtra.dispatch(recordExtra);

              // Retorna uma promise resolvida
              return Promise.resolve();
            })
          : []; // Retorna um array vazio se cadastrosAdicionais for null ou undefined

      // Chama Promise.all somente se houver promessas no array
      if (cadastrosAdicionaisPromises.length > 0) {
        await Promise.all(cadastrosAdicionaisPromises);
      }
    };

    // Função para lidar com o registro carregado
    const handleRegistroCarregado = (results) => {
      if ((id || obterUnico) && onRegistroCarregado) {
        onRegistroCarregado(results[0]);
      } else if ((id || obterUnico) && dispatchForm) {
        dispatchForm(results[0]);
      }
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          // Criação das listas de promessas separadas
          const registroPromises = getRegistroPromises();
          const registroResults = await Promise.all(registroPromises);
          //promessas dependências
          const dependenciaPromises = getDependenciaPromises();
          const subCadastroPromises = getSubCadastroPromises();
          const cadastroExtraObter = getCadastroExtraPromises(
            id ? registroResults[0] : null
          );

          // Resolução das promessas separadamente

          const dependenciaResults = await Promise.all(dependenciaPromises);
          const subCadastroResults = await Promise.all(subCadastroPromises);

          const cadastroExtraPromises = cadastroExtraObter.map((item) => {
            return item.promisse;
          });

          const cadastroExtras = cadastroExtraObter.map((item) => {
            return item.cadastro;
          });

          const cadastroExtraResults = await Promise.all(cadastroExtraPromises);

          // Processamento dos resultados
          processDependencies(dependenciaResults);
          await processSubCadastros(subCadastroResults);
          await processExtraCadastros(cadastroExtras, cadastroExtraResults);

          // Registro carregado
          handleRegistroCarregado(registroResults);

          if (onReady) {
            onReady(id ? registroResults[0] : null);
          }

          setIsLoading(false);
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }, []);

    const container = (
      <>
        {isLoading ? (
          <Loading />
        ) : (
          <Card>
            <Form
              className={!childrenTabs ? "px-4 py-3" : ""}
              title={tituloMensagem}
              routeBack={urlBack}
              id={id}
              postSuccessMessage={id ? null : saveMessage}
              putSuccessMessage={id || obterUnico ? saveMessage : null}
              childrenFormList={subCadastros}
              childrenFileList={arquivos}
              additionalFormList={cadastrosAdicionais}
              urlSubmit={urlSubmit}
              contentType={tipoConteudo}
              onSuccess={onRegistroSalvo}
              manualHandleSuccess={tratarSalvoManual}
              beforeSubmitHandler={beforeSubmitHandler}
              customValidation={customValidation}
              askNewRecord={perguntarNovoRegistro}
              showSuccessMessage={mostrarMensagemSucesso}
              uniqueRecord={obterUnico}
              onSubmit={onSalvar}
              onSubmitStart={() => {
                setIsLoading(true);
              }}
              onSubmitEnd={() => setIsLoading(false)}
              ref={ref}
            >
              {children}
              {!modal && (
                <footer>
                  {urlBack && (
                    <button
                      type="button"
                      className="btn btn-danger btn-cancel-register"
                      onClick={handleCancelar}
                    >
                      Cancelar
                    </button>
                  )}

                  <button
                    type="submit"
                    className={`btn ${classeBotaoConfirmar} btn-submit-register`}
                  >
                    {textoBotaoConfirmar}
                  </button>
                </footer>
              )}
            </Form>
          </Card>
        )}
      </>
    );
    return (
      <React.Fragment>
        {!modal ? (
          <PaginaLayout
            titulo={titulo}
            modulo={modulo}
            item={item}
            temFavorito={podeFavorito && !id}
            funcao={funcao}
          >
            {container}
          </PaginaLayout>
        ) : (
          <>{container}</>
        )}
      </React.Fragment>
    );
  }
);
