import React, { useEffect } from "react";

import { Outlet, useRoutes } from "react-router-dom";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/gomelius.scss";
import "./assets/scss/select-filter.scss";

import { AuthProvider } from "./contexts/authContext";
import routes from "./routes";
import { BroadcastProvider } from "./contexts/broadcastContext";
import { AdminUtil, Util } from "./util";
import { layoutTypes } from "./constants";
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  changelayoutMode,
} from "../src/slices/thunks";
import { useDispatch } from "react-redux";
const App = () => {
  const content = useRoutes(routes);
  const dispatch = useDispatch();
  // Função para ajustar o atributo data-vp com base no tamanho da tela
  const updateScreenSizeAttribute = () => {
    var config = AdminUtil.obterConfiguracao();
    const screenSize = Util.getScreenSize();
    const currentVp = document.body.getAttribute("data-vp");

    dispatch(changeLayout(config.Layout));
    dispatch(changelayoutMode(config.Tema));
    dispatch(changeLayoutWidth(config.LarguraBarraLateral));
    dispatch(changeTopbarTheme(config.BarraSuperiorTema));

    if (config.Layout === layoutTypes.VERTICAL) {
      dispatch(changeSidebarType(config.TipoBarraLateral));
      dispatch(changeSidebarTheme(config.BarraLateralTema));
    }
    if (
      screenSize === "xs" ||
      (screenSize === "sm" &&
        document.body.classList.contains("sidebar-enable"))
    ) {
      document.body.classList.remove("sidebar-enable");
    }
    // Atualiza o atributo apenas se for diferente
    if (currentVp !== screenSize) {
      document.body.setAttribute("data-vp", screenSize);
    }
  };

  useEffect(() => {
    // Chama a função inicialmente para definir o atributo
    updateScreenSizeAttribute();

    // Adiciona o event listener para o evento resize
    window.addEventListener("resize", updateScreenSizeAttribute);

    // Remove o event listener ao desmontar o componente
    return () => {
      window.removeEventListener("resize", updateScreenSizeAttribute);
    };
  }, []);
  return (
    <React.Fragment>
      <AuthProvider>
        <BroadcastProvider>
          {content}
          <Outlet />
        </BroadcastProvider>
      </AuthProvider>
    </React.Fragment>
  );
};

export default App;
