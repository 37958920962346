export class PermissaoMapa {
  // Array com os códigos das funções (mapeamento de permissões)
  public static readonly Funcoes: number[] = [
    // Grupo Cadastro
    // Módulo Cliente
    // Função Cliente
    1001,
    10011,
    10012,
    10013,
    10014,
    10015,
    // Função Grupo
    1003,
    10031,
    10032,
    10033,
    10034,
    10035,
    // Função Atividade
    1005, 
    // Módulo Financeiro
    // Função Categoria
    2001, 
    // Função Conta
    2003, 
    // Função Plano de Pagamento
    2005, 
    // Módulo Sistema
    3001, // Função Motivo
    3003, // Função Usuário
    3004, // Função Convite
    3006, // Função Departamento
    3008, // Função Filial
    3010, // Função Minha Empresa
    3011, // Função Papel
    // Módulo Fornecedor
    4001, // Função Fornecedor
    // Módulo Venda
    5001, // Função Supervisor
    5003, // Função Agente
    5005, // Função Vendedor
    5007, // Função Tabela de Preço
    // Módulo Veículo
    6001, // Função Veículo Trator
    6003, // Função Carreta
    6005, // Função Marca
    6007, // Função Modelo de Trator
    // Módulo Serviço
    7001, // Função Serviço
    // Módulo Transportador
    8001, // Função Transportador
    // Módulo AET
    9001, // Função AET
    9003, // Função Trecho
    9005, // Função Engenheiro
    9007, // Função ART
    // Grupo Venda
    // Módulo Ordem de Serviço
    10001, // Função Ordens
    // Módulo Orçamento
    11001, // Função Ordem
    // Grupo Financeiro
    // Módulo Receita
    12001, // Função Títulos
    12003, // Função Registrar Pagamento
    12004, // Função Confirmar Pagamento
    12005, // Função Cancelar
    12006, // Função Estornar
    // Módulo Despesa
    13001, // Função Títulos
    13003, // Função Registrar Pagamento
    13004, // Função Cancelar
    13005, // Função Estornar
    // Grupo Relatório
    // Módulo Financeiro
    200001, // Função Fluxo Mensal
    200002, // Função Fluxo Anual - Receita
    200003, // Função Fluxo Anual - Despesa
    // Módulo Receita
    201001, // Função Receber Por Data
    201002, // Função Atrasados Por Data
    // Módulo Despesa
    203001, // Função Atrasados Por Data
  ];

  // Método para obter a posição do bit correspondente ao funcaoId
  public static obterPosicaoBit(funcaoId: number): number {
    return PermissaoMapa.Funcoes.indexOf(funcaoId);
  }

  // Método inverso: obter o funcaoId com base na posição do bit
  public static obterPermissaoPorBit(bitPosition: number): number {
    if (bitPosition >= 0 && bitPosition < PermissaoMapa.Funcoes.length) {
      return PermissaoMapa.Funcoes[bitPosition]; // Retorna o funcaoId
    }
    return -1; // Retorna -1 se a posição do bit for inválida
  }
}
