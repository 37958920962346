import React, { useEffect } from "react";
import * as signalR from "@microsoft/signalr";
import gomelius from "src/api/gomelius";
import { ToastNotification } from "src/util";
import { NotificacaoCard } from "../NotificacaoCard";
import { Position } from "src/util/ToastNotification";

import { atualizarLayout } from "src/slices/thunks";
import { useDispatch } from "react-redux";
import { useBroadcast } from "src/contexts";
const NotificacaoHub = () => {
  const dispatch = useDispatch();
  const { sendMessage } = useBroadcast();
  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_HUB, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => {
          return gomelius.getToken();
        },
      })
      .build();

    connection.on("Nova", (registro) => {
      var totalNotificacoes = localStorage.getItem("TotalNotificacao");
      if (totalNotificacoes !== null && totalNotificacoes !== undefined) {
        var novoTotal = parseInt(totalNotificacoes) + 1;
        localStorage.setItem(
          "TotalNotificacao",
          novoTotal as unknown as string
        );
      } else {
        localStorage.setItem("TotalNotificacao", "1");
      }
      //dispatch(atualizarLayout());
      sendMessage({
        type: "notificacao",
        subType: "1",
      });
      const content = (
        <NotificacaoCard
          dados={registro}
          marcarComoLidoClick={false}
          mostrarData={false}
        />
      );
      ToastNotification.info(content, null, Position.TOP_RIGHT, null, false);
    });

    connection
      .start()
      .catch((error) =>
        console.error("Erro ao conectar ao SignalR Hub:", error)
      );

    return () => {
      connection.stop();
    };
  }, []);

  return <></>; // Você pode retornar qualquer coisa aqui, ou nada se não for necessário renderizar nada
};

export default NotificacaoHub;
